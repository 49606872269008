import { IShellServices, PageDetails, RouteInfo, StateService } from "@glideroggan/common";
import { FASTElement, Observable, customElement, observable } from "@microsoft/fast-element";
import { styles, template } from "./templates";


@customElement({
    name: 'router-view',
    template: template,
    styles: styles,
})
export class RouterView extends FASTElement {
    @observable routeInfo: RouteInfo
    @observable state: string = 'nostate';
    @observable targetPage: string = '';
    @observable HTML: string = '';
    @observable error: {
        routeElement?: string,
        endpoint?: string,
        exposes?: string,
        error?: string
    } = {}
    disableHashChange: boolean = false
    constructor() {
        super();

        StateService.shellConnected.on((services?:IShellServices) => this.shellConnected(services!))
        window.onhashchange = this.hashChanged.bind(this);
    }
    connectedCallback(): void {
        super.connectedCallback();
    }
    shellConnected(services: IShellServices) {
        services.logIn.on(async () => {
            // navigate to the home page
            // console.log('RouterView-loggedIn')
            const route = await StateService.getRouteInfo(this, 'home');
            await this.navigate(route);
        })
        services.navigate.on(async (pageData?: PageDetails) => {
            // console.log(`RouterView-OnNavigate: `, pageData)
            this.navigateToPage(pageData!);
        })
        services.logOut.on(async () => {
            // navigate to the login page
            this.navigateToPage({page:'#landing'});
        })
    }
    async hashChanged() {
        if (this.disableHashChange) return
        // console.log('hashchange: ', window.location.hash)
        const route = window.location.hash;

        // check the route-service if this is a page change or MF change
        await this.handleRouteChange({page:route});
    }
    private async handleRouteChange(pageData: PageDetails) {
        let routeInfo: RouteInfo
        try {
            routeInfo = await StateService.getRouteInfo(this, pageData.page.slice(1))
            if (routeInfo == null) {
                this.state = 'notfound'
                this.targetPage = pageData.page
            } else {
                routeInfo.params = pageData.params ?? {};
                await this.navigate(routeInfo);
            }
        } catch (error) {
            console.error('error: ', error)
            // show error page, 404, this.render404()
            this.state = 'error'
        }
    }
    private async navigate(routeInfo: RouteInfo): Promise<void> {
        this.routeInfo = routeInfo;
        // don't trigger on hash change when we are navigating
        
        // history.replaceState({}, '', `#${routeInfo.route}`)
        history.pushState({}, '', `#${routeInfo.route}`)
        const params = btoa(JSON.stringify(this.routeInfo.params))
        this.state = 'loading'
        if (routeInfo.legacy) {
            this.HTML = `<${routeInfo.routeElement} params="${params}"></${routeInfo.routeElement}>`
            this.state = 'loaded'
        } else {
            try {
                console.debug('loading: ', `${this.routeInfo.endpoint}/${this.routeInfo.exposes}`)
                // TODO: hack to use the import maps
                if (this.routeInfo.endpoint.startsWith('@')) {
                    const mod = await import(`${this.routeInfo.endpoint}`)
                    // console.log('mod: ', mod)
                } else {
                    const mod = await import(`${this.routeInfo.endpoint}/${this.routeInfo.exposes}`)
                }
                
                this.HTML = `<${routeInfo.routeElement} params='${params}'></${routeInfo.routeElement}>`
                this.state = 'loaded'
            } catch (error) {
                console.error('error: ', error)
                this.error = {
                    routeElement: routeInfo.routeElement,
                    endpoint: routeInfo.endpoint,
                    exposes: routeInfo.exposes,
                    error: error as string
                }
                this.state = 'error'
                Observable.notify(this, 'state')
            }
        }
    }
    public async navigateToPage(pageData: PageDetails) {
        if (StateService.isLoggedIn && pageData.page.slice(1) == 'login') {
            await this.handleRouteChange({page:'#home'})
        } else {

            await this.handleRouteChange(pageData)
        }
    }
}

